/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import QuestionRiskEvent from '../../events/QuestionRiskEvent';
import { EventSystem } from '../../events/EventSystem';
import { ActionPlaceholderData } from '../../models/Form';
import StringUtils from '../../utils/StringUtils';
import Tooltip from '../shared/Tooltip';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { Input } from '../shared/form-control/Input';
import MultiTextField from '../shared/form-control/MultiTextField';
import SingleUserSelect from '../ownership/SingleUserSelect';
import { ClientFormUser } from '../../models/ClientFormUser';
import { Risk, RiskStatus, RiskStatusKeys, riskStatusOptions } from '../../models/Risk';
import { Option } from '../Option';
import RiskService from '../../services/RiskService';
import LanguageUtils from '../../utils/LanguageUtils';
import QuestionRiskEditEvent from '../../events/QuestionRiskEditEvent';
import QuestionRiskViewEvent from '../../events/QuestionRiskViewEvent';
import RiskUtils, { RiskLevel } from '../../utils/RiskUtils';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import { TabStrip } from '../shared/tab-strip/TabStrip';
import ActivityFeed from '../activity-feed/ActivityFeed';
import { AuditFeedItem, AuditType } from '../../models/AuditFeed';
import { ApiResponse } from '../../models/ApiResponse';
import ActivityFeedService from '../../services/ActivityFeedService';
import { Placeholders } from '../../models/Placeholders';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';

const RiskModal = () => {
  const { t } = useTranslation(['risk']);
  const [open, setOpen] = useState(false);
  const [isSvaing, setIsSaving] = useState(false);
  const [actionId, setActionId] = useState('');
  const [actionTitle, setActionTitle] = useState<string | ReactElement[]>('');
  const [clientFormId, setClientFormId] = useState('');
  const [clientFormTitle, setClientFormTitle] = useState<string>();
  const [placeholders, setPlaceholders] = useState<Record<string, ActionPlaceholderData>>({});
  const [selectedOwner, setSelectedOwner] = useState<ClientFormUser>();
  const [selectedRiskStatus, setSelectedRiskStatus] = useState<Option<string, string | RiskStatus>>(
    riskStatusOptions(t).find((x) => x.value === RiskStatus.identified)!,
  );
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [changeReason, setChangeReason] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [riskFriendlyId, setRiskFriendlyId] = useState(0);

  const [probabilityOptions, setProbabilityOptions] = useState<Option<string, RiskLevel>[]>([]);
  const [impactOptions, setImpactOptions] = useState<Option<string, RiskLevel>[]>([]);

  const [selectedProbability, setSelectedProbability] = useState<Option<string, RiskLevel>>();
  const [selectedImpact, setSelectedImpact] = useState<Option<string, RiskLevel>>();

  const [mode, setMode] = useState<'create' | 'edit' | 'view'>('create');
  const [isDirty, setIsDirty] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<string>('');
  const [auditFeed, setAuditFeed] = useState<ApiResponse<AuditFeedItem[]>>({} as ApiResponse<AuditFeedItem[]>);

  const set = useCallback(
    (risk: Risk) => {
      setId(risk.id);
      setClientFormTitle(
        risk.documentNumber !== null
          ? `${StringUtils.makePrefixWithNumber(undefined, risk.documentNumber, risk.templateModuleTranslations)}-${risk.clientFormSubtitle}`
          : risk.clientFormSubtitle,
      );
      setActionId(risk.actionId);
      setActionTitle(
        interpolateActionData(
          LanguageUtils.getTranslation('question', risk.actionTranslations) || LanguageUtils.getTranslation('title', risk.actionTranslations),
          risk.placeholders,
        ),
      );
      setClientFormId(risk.clientFormId);
      setPlaceholders(risk.placeholders);
      const riskStatus = riskStatusOptions(t).find((x) => x.value === risk.status)!;
      setSelectedRiskStatus(riskStatus);
      setTitle(risk.title);
      setDescription(risk.description || '');
      const probability = probabilityOptions.find((x) => x.id === risk.matrixHorizontalId);
      setSelectedProbability(probability);
      const impact = impactOptions.find((x) => x.id === risk.matrixVerticalId);
      setSelectedImpact(impact);
      const owner = { id: risk.ownerId, firstName: '', lastName: '', role: ClientFormUserRole.Owner };
      setSelectedOwner(owner);
      setRiskFriendlyId(risk.friendlyId);
      setInitialFormValues(JSON.stringify([risk.title, risk.description, owner, riskStatus, probability, impact]));
    },
    [impactOptions, probabilityOptions, t],
  );

  useEffect(() => {
    setIsDirty(JSON.stringify([title, description, selectedOwner, selectedRiskStatus, selectedProbability, selectedImpact]) !== initialFormValues);
  }, [description, initialFormValues, selectedOwner, selectedRiskStatus, selectedImpact, selectedProbability, title]);

  useEffect(() => {
    const handler = (event: QuestionRiskEvent) => {
      setActionId(event.action.id);
      setActionTitle(interpolateActionData(event.action?.data?.question || '', event.placeholders));
      setClientFormTitle(event.clientFormTitle);
      setClientFormId(event.clientFormId);
      setPlaceholders(event.placeholders);
      setMode('create');
      setOpen(true);
    };
    EventSystem.listen('question-risk-new', handler);
    return () => EventSystem.stopListening('question-risk-new', handler);
  }, [placeholders]);

  useEffect(() => {
    const handler = (event: QuestionRiskEditEvent) => {
      set(event.risk);
      setMode('edit');
      setOpen(true);
    };
    EventSystem.listen('question-risk-edit', handler);
    return () => EventSystem.stopListening('question-risk-edit', handler);
  }, [impactOptions, probabilityOptions, set, t]);

  useEffect(() => {
    const handler = (event: QuestionRiskViewEvent) => {
      set(event.risk);
      setMode('view');
      setOpen(true);
    };
    EventSystem.listen('question-risk-view', handler);
    return () => EventSystem.stopListening('question-risk-view', handler);
  }, [impactOptions, probabilityOptions, set, t]);

  useEffect(() => {
    RiskService.getRiskCategories({ pageNumber: 1, pageSize: 1 }).then((res) => {
      const defaultCategory = res.data[0];
      setSelectedCategoryId(defaultCategory.id);
      setImpactOptions(
        defaultCategory.matrix.columnIds
          .map((x) => ({
            id: x,
            text: LanguageUtils.getTranslation(x, defaultCategory.matrix.translations),
            value: defaultCategory.matrix.levels[x] as RiskLevel,
          }))
          .sort((a, b) => b.value - a.value),
      );
      setProbabilityOptions(
        defaultCategory.matrix.rowIds
          .map((x) => ({
            id: x,
            text: LanguageUtils.getTranslation(x, defaultCategory.matrix.translations),
            value: defaultCategory.matrix.levels[x] as RiskLevel,
          }))
          .sort((a, b) => b.value - a.value),
      );
    });
  }, []);

  const isValid = useMemo(
    () => title && actionId && selectedCategoryId && selectedProbability?.id && selectedImpact?.id && selectedOwner,
    [actionId, selectedOwner, selectedCategoryId, selectedImpact?.id, selectedProbability?.id, title],
  );

  const isEditSaveValid = useMemo(() => isValid && !!changeReason && isDirty, [changeReason, isDirty, isValid]);

  const reset = useCallback(() => {
    setId('');
    setRiskFriendlyId(0);
    setClientFormTitle('');
    setActionId('');
    setActionTitle('');
    setTitle('');
    setDescription('');
    setChangeReason('');
    setSelectedProbability(undefined);
    setSelectedImpact(undefined);
    setSelectedOwner(undefined);
    setSelectedRiskStatus(riskStatusOptions(t).find((x) => x.value === RiskStatus.identified)!);
    setIsDirty(false);
  }, [t]);

  const createRisk = useCallback(() => {
    if (isValid && selectedOwner?.id && selectedProbability?.id && selectedImpact?.id) {
      setIsSaving(true);
      RiskService.createRisk({
        clientFormId: clientFormId,
        actionId: actionId,
        riskCategoryId: selectedCategoryId,
        title: title,
        description: description,
        matrixHorizontalId: selectedProbability?.id,
        matrixVerticalId: selectedImpact?.id,
        ownerId: selectedOwner?.id,
        status: selectedRiskStatus.value as RiskStatus,
      })
        .then((res) => {
          EventSystem.fireEvent('question-risk-created', { risk: res.data });
          reset();
        })
        .finally(() => {
          setIsSaving(false);
          setOpen(false);
        });
    }
  }, [
    actionId,
    clientFormId,
    description,
    isValid,
    selectedOwner?.id,
    reset,
    selectedRiskStatus.value,
    selectedCategoryId,
    selectedImpact?.id,
    selectedProbability?.id,
    title,
  ]);

  const saveRisk = useCallback(() => {
    if (isEditSaveValid && selectedOwner?.id && selectedProbability?.id && selectedImpact?.id) {
      setIsSaving(true);
      RiskService.updateRisk(id, {
        clientFormId: clientFormId,
        actionId: actionId,
        riskCategoryId: selectedCategoryId,
        title: title,
        description: description,
        matrixHorizontalId: selectedProbability?.id,
        matrixVerticalId: selectedImpact?.id,
        ownerId: selectedOwner?.id,
        status: selectedRiskStatus.value as RiskStatus,
        reasonForChange: changeReason,
      })
        .then((res) => {
          EventSystem.fireEvent('question-risk-created', { risk: res.data });
          reset();
        })
        .finally(() => {
          setIsSaving(false);
          setOpen(false);
        });
    }
  }, [
    actionId,
    changeReason,
    clientFormId,
    description,
    id,
    isEditSaveValid,
    selectedOwner?.id,
    reset,
    selectedRiskStatus.value,
    selectedCategoryId,
    selectedImpact?.id,
    selectedProbability?.id,
    title,
  ]);

  const onClose = useCallback(() => {
    reset();
    setOpen(false);
  }, [reset]);

  const assignCategoriesToPlaceholders = useCallback(
    (placeholders: Record<string, any>, placeholder: Placeholders) => {
      const typedPlaceholders: { [key: string]: any } = placeholders[placeholder];
      if (typedPlaceholders) {
        // Assign impactOptions and probabilityOptions
        typedPlaceholders['impactOptions'] = impactOptions;
        typedPlaceholders['probabilityOptions'] = probabilityOptions;
        placeholders[placeholder] = typedPlaceholders;
      }
    },
    [impactOptions, probabilityOptions],
  );

  const getAuditFeed = useCallback(
    (pageNumber = 1) => {
      if (id && clientFormId) {
        ActivityFeedService.getActivityFeed({
          clientFormId: clientFormId,
          feedType: AuditType.risk,
          feedSourceId: id,
          pageNumber: pageNumber,
        }).then((res) => {
          setAuditFeed((prev) => {
            const feedPaged = { ...res };
            if (prev.data && pageNumber > 1) {
              feedPaged.data = [...prev.data, ...res.data];
            }
            feedPaged.data.forEach((x) => {
              assignCategoriesToPlaceholders(x.placeholders, Placeholders.RISK_UPDATED_SINGULAR);
              assignCategoriesToPlaceholders(x.placeholders, Placeholders.RISK_UPDATED_MULTIPLE);
              assignCategoriesToPlaceholders(x.placeholders, Placeholders.RISK_FLAGGED);
              if (x.placeholders[Placeholders.PRIMARY_USER]) {
                x.createdBy = (x.placeholders[Placeholders.PRIMARY_USER] as any).userName;
                x.createdById = (x.placeholders[Placeholders.PRIMARY_USER] as any).id;
              }
            });
            return feedPaged;
          });
        });
      }
    },
    [assignCategoriesToPlaceholders, clientFormId, id],
  );

  useEffect(() => {
    if (open && mode === 'view') {
      getAuditFeed();
    }
  }, [getAuditFeed, mode, open]);

  return (
    <ModalContext.Provider value={{ open: open, modalWidth: 'xl:w-[800px] w-3/5', onClose: onClose, showCloseButton: true }}>
      <StandardModal
        title={mode === 'create' ? t('add-modal-title') : t('edit.modal-title', { id: riskFriendlyId })}
        cancelButtonTitle={t('buttons.cancel')}
        confirmButtonTitle={mode === 'create' ? t('buttons.add') : t('buttons.save')}
        onCancelClick={mode !== 'view' ? () => onClose() : undefined}
        onConfirmClick={mode !== 'view' ? (mode === 'create' ? createRisk : saveRisk) : undefined}
        confirmDisabled={!isValid || (!isEditSaveValid && mode === 'edit') || isSvaing}
        confirmLoading={isSvaing}
        confirmDisabledTooltip={!isValid || (!isEditSaveValid && mode === 'edit') ? t('validation.all-fields-required') : ''}
      >
        <div className="min-h-[55vh] w-full">
          {mode !== 'view' && (
            <div className="flex flex-col">
              <div className="text-gray-2 font-medium">{clientFormTitle}</div>
              <Tooltip text={actionTitle} truncatedTextMode>
                {(tooltip) => (
                  <div {...tooltip} className="truncate">
                    {actionTitle}
                  </div>
                )}
              </Tooltip>
            </div>
          )}
          {mode !== 'view' && (
            <>
              <div className="mt-6 flex items-start justify-between gap-12">
                <div className="w-full">
                  <DropdownSelect
                    options={riskStatusOptions(t)}
                    value={selectedRiskStatus}
                    onChange={(option) => {
                      setSelectedRiskStatus(option);
                    }}
                    label={t('form-labels.status')}
                    aria-label={t('form-labels.status')}
                  />
                </div>
                <div className="w-full">
                  <div className="text-color-3 text-dpm-12 pb-1">
                    <span className="text-semantic-3 pr-1 font-semibold">*</span>
                    {t('form-labels.assign-owner')}
                  </div>
                  <div>
                    <SingleUserSelect
                      user={selectedOwner}
                      onAddUser={(user) => setSelectedOwner(user)}
                      onRemoveUser={() => setSelectedOwner(undefined)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="w-full">
                  <Input label={t('form-labels.title')} value={title} onChange={(e) => setTitle(e.target.value)} required maxLength={50} />
                </div>
              </div>
              <div className="mt-2 flex">
                <div className="w-full">
                  <MultiTextField
                    label={t('form-labels.description')}
                    aria-label={t('form-labels.description')}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-6 flex items-start justify-between gap-12">
                <div className="w-full">
                  <DropdownSelect
                    options={probabilityOptions}
                    value={selectedProbability}
                    onChange={(option) => setSelectedProbability(option)}
                    label={t('form-labels.probability')}
                    aria-label={t('form-labels.probability')}
                    placeholder={t('form-labels.choose')}
                    helpText={t('help.probability')}
                    required
                  />
                </div>
                <div className="w-full">
                  <DropdownSelect
                    options={impactOptions}
                    value={selectedImpact}
                    onChange={(option) => setSelectedImpact(option)}
                    label={t('form-labels.impact')}
                    aria-label={t('form-labels.impact')}
                    placeholder={t('form-labels.choose')}
                    helpText={t('help.impact')}
                    required
                  />
                </div>
              </div>
              {mode === 'edit' && isDirty && (
                <div className="mt-2 flex">
                  <div className="w-full">
                    <MultiTextField
                      label={t('form-labels.change-reason')}
                      aria-label={t('form-labels.change-reason')}
                      value={changeReason}
                      onChange={(e) => setChangeReason(e.target.value)}
                      required
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {mode === 'view' && (
            <TabStrip borderless contentClassName="py-4" defaultTabId="details">
              <TabStrip.TabHeader id="details" text={t('tabs.details')} value={null} data-cy="details-tab" />
              <TabStrip.TabHeader id="history" text={t('tabs.history')} value={null} data-cy="history-tab" />
              <TabStrip.TabContent forId="details">
                <>
                  <div className="flex flex-col">
                    <div className="text-gray-2 font-medium">{clientFormTitle}</div>
                    <Tooltip text={actionTitle} truncatedTextMode>
                      {(tooltip) => (
                        <div {...tooltip} className="truncate">
                          {actionTitle}
                        </div>
                      )}
                    </Tooltip>
                  </div>
                  <div className="mt-6 flex items-start justify-between gap-12">
                    <div className="w-full">
                      <div className="font-medium">{t('form-labels.status')}</div>
                      <div>{t(RiskStatusKeys[selectedRiskStatus.value as RiskStatus])}</div>
                    </div>
                    <div className="w-full">
                      <div className="font-medium">{t('form-labels.owner')}</div>
                      <div>
                        <SingleUserSelect
                          user={selectedOwner}
                          onAddUser={(user) => setSelectedOwner(user)}
                          onRemoveUser={() => setSelectedOwner(undefined)}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full py-2">
                    <div className="font-medium">{t('form-labels.title')}</div>
                    <div>{title}</div>
                  </div>
                  <div className="w-full py-2">
                    <div className="font-medium">{t('form-labels.description')}</div>
                    <div>{description || '-'}</div>
                  </div>
                  <div className="flex items-start justify-between gap-12 py-2">
                    <div className="w-full">
                      <div className="font-medium">{t('form-labels.probability')}</div>
                      <div className={`font-[450] ${selectedProbability ? RiskUtils.textColorForRiskLevel(selectedProbability?.value) : ''}`}>
                        {selectedProbability?.text}
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="font-medium">{t('form-labels.impact')}</div>
                      <div className={`font-[450] ${selectedImpact ? RiskUtils.textColorForRiskLevel(selectedImpact?.value) : ''}`}>
                        {selectedImpact?.text}
                      </div>
                    </div>
                  </div>
                </>
              </TabStrip.TabContent>
              <TabStrip.TabContent forId="history">
                <div className="max-h-[420px] overflow-y-auto">
                  <ActivityFeed feed={auditFeed} onLoadMore={getAuditFeed} />
                </div>
              </TabStrip.TabContent>
            </TabStrip>
          )}
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default RiskModal;
