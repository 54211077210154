export enum AuditType {
  clientForm = 0,
  risk = 1,
}

export type AuditFeedItem = {
  text: string;
  placeholders: Record<string, unknown>;
  createdUtc: string;
  createdBy: string;
  createdById: string;
};
