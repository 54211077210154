import { AxiosRequestConfig } from 'axios';
import { ApiResponse } from '../models/ApiResponse';
import { PagingFilter } from '../models/Paging';
import { Risk, RiskCategory, RiskRequest, RiskUpdateRequest } from '../models/Risk';
import BaseService from './BaseService';
import { ClientFormTitleResponse } from '../models/ClientForm';

type RiskCategoryPagedFilter = PagingFilter;
type RiskPagedFilter = PagingFilter & {
  clientFormIds?: string[];
  ownerIds?: string[];
  ratings?: string | number[];
  statuses?: string | number[];
};

class RiskService extends BaseService {
  public static getRisks(clientId: string, filter: RiskPagedFilter, config?: AxiosRequestConfig): Promise<ApiResponse<Risk[]>> {
    return this.get(`/v1/risks`, { ...config, params: { clientId, ...filter } });
  }

  public static getRiskCategories(filter: RiskCategoryPagedFilter): Promise<ApiResponse<RiskCategory[]>> {
    return this.get(`/v1/risks/categories`, { params: filter });
  }

  public static getRiskClientForms(clientId: string, filter: PagingFilter): Promise<ApiResponse<ClientFormTitleResponse[]>> {
    return this.get(`/v1/risks/clientForms`, { params: { clientId, ...filter } });
  }

  public static createRisk(risk: RiskRequest): Promise<ApiResponse<Risk>> {
    return this.post(`/v1/risks`, risk);
  }

  public static updateRisk(id: string, risk: RiskUpdateRequest): Promise<ApiResponse<Risk>> {
    return this.put(`/v1/risks/${id}`, risk);
  }
}

export default RiskService;
